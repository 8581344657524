import Components18a from './Components18a/Components18a';
import './App.css';

function App() {
  return (
    <div id='App'> 
      <Components18a></Components18a>
    </div>
  );
}

export default App;
