import './Middle1a13p18.scss'
// const express = require('express');
// const app = express();
// app.get('/privact', (req, res) => {
//     res.send("this is your privacy policy page")
// })

// app.listen(3600)

function Middle1a13p18(props) {

    return (
        <div id="Middle1a13p18">

            <div id='middle'>
                <div id='topnav'>

                    <div id='leftbarhandler'>
                        <input type='checkbox' id='check_input'>
                        </input>
                        <label htmlFor='check_input'>
                            <span class="material-symbols-rounded" id="open-leftbar">
                                menu
                            </span>
                            <span class="material-symbols-rounded" id="close-leftbar">
                                close
                            </span>
                        </label>
                        {props.leftbar}
                    </div>
                </div>



                <div id='content'>

                    <div id='card_view'>

                        {props.quote.map((quote_list_data) => {
                            return (
                                <div id='quote_list_data'>
                                    <p>{quote_list_data}</p>
                                    {/* <div id='icon'>
                                        <span class="material-symbols-rounded">
                                            content_copy
                                        </span>
                                        <span class="material-symbols-rounded">
                                            send
                                        </span>
                                    </div> */}
                                </div>
                            )
                        })}


                    </div>

                </div>
            </div>

        </div>
    )
}

export default Middle1a13p18;
