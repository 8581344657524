import Leftbar1a5p18 from './Leftbar1a5p18/Leftbar1a5p18';
import Middle1a13p18 from './Middle1a13p18/Middle1a13p18';
import Rightbar1a18p18 from './Rightbar1a18p18/Rightbar1a18p18';
import './Components18a.scss'
import React,{useState} from 'react';


const Goodmorning = require('../DataHost/goodmorning.json');
const Chattips = require('../DataHost/chattips.json');
const Funny = require('../DataHost/funny.json');
const Goodnight = require('../DataHost/goodnight.json');
const Jokes = require('../DataHost/jokes.json');
const Motivational = require('../DataHost/motivational.json');
const Romantic = require('../DataHost/romantic.json');
const Shayari = require('../DataHost/shayari.json');
const Status = require('../DataHost/status.json');



function Components18a() {
    const [data,setdata] = useState(Goodmorning);

    function fgoodmorning() {
       setdata(Goodmorning)
    }
    function fgoodnight() {
        setdata(Goodnight)
    }
    function fromantic() {
        setdata(Romantic)
    }
    function fchattips() {
        setdata(Chattips)
    }
    function fshayari() {
        setdata(Shayari)
    }
    function fmotivational() {
        setdata(Motivational)
    }
    function fjokes() {
        setdata(Jokes)
    }
    function ffunny() {
        setdata(Funny)
    }
    function fstatus() {
        setdata(Status)
    }
    return (
        <div id='Components18a'>

            <Leftbar1a5p18 Goodmorning={fgoodmorning} Goodnight={fgoodnight} Romantic={fromantic} Chattips={fchattips} Shayari={fshayari} Motivational={fmotivational} Jokes={fjokes} Funny={ffunny} Status={fstatus}></Leftbar1a5p18>



            <Middle1a13p18 quote={data} leftbar={<Leftbar1a5p18 Goodmorning={fgoodmorning} Goodnight={fgoodnight} Romantic={fromantic} Chattips={fchattips} Shayari={fshayari} Motivational={fmotivational} Jokes={fjokes} Funny={ffunny} Status={fstatus}></Leftbar1a5p18>}></Middle1a13p18>



            <Rightbar1a18p18 ></Rightbar1a18p18>

        </div>
    )
}

export default Components18a;