import './Rightbar1a18p18.scss'

function Rightbar1a18p18(props){
    return(
        <div id="Rightbar1a18p18">
            <div id='ad12'>
                
            </div>
        {props.leftbar}
        </div>
    )
}

export default Rightbar1a18p18;