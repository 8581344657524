import './Leftbar1a5p18.scss'
// import { useState } from 'react';
import instagram_icon from '../../res/UI/social_midea/instagram_black.svg';
import youtube_icon from '../../res/UI/social_midea/youtube_black.svg';



function Leftbar1a5p18(props) {



    // const quote_list = ["Good Morning", "Good Night", "Romantic", "Chat Tips", "Shayari", "Motivational", "Jokes", "Funny", "Status"]
    const social_media_icon = ["Facebook", "Instagram", "Twitter", "Youtube"]
    const aboutUs = ["Privacy & Policy"]
    const elementClasses = {
        website_name: ["bd-1red", "bg-red"]
    }
    return (
        <div id='Leftbar1a5p18'>
            <div id='website_name' className={elementClasses.website_name.join(' ')}>
                <h1>Chat helpie</h1>
            </div>
            <div id='list_item'>
                <div id='list_item_child'>
                    <ul>
                        <li onClick={props.Goodmorning}>Good Morning</li>
                        <li onClick={props.Goodnight}>Good Night</li>
                        <li onClick={props.Romantic}>Romantic</li>
                        <li onClick={props.Chattips}>Chat Tips</li>
                        <li onClick={props.Shayari}>Shayari</li>
                        <li onClick={props.Motivational}>Motivational</li>
                        <li onClick={props.Jokes}>Jokes</li>
                        <li onClick={props.Funny}>Funny</li>
                        <li onClick={props.Status}>Status</li>
                    </ul>
                </div>

            </div>
            <div id='social_media'>
                <div id='social_media_icon'>
                    <a href='https://www.instagram.com/chathelpie/'>
                        <img src={instagram_icon}></img>
                    </a>

                </div>
                <div id='social_media_icon'>
                    <a href='https://www.youtube.com/channel/UCBRI2PDVNeFdiWFsSGrgWlw'>
                        <img src={youtube_icon}></img>
                    </a>

                </div>
            </div>



            <div id='about_us'>
                {
                    aboutUs.map((aboutUs) => {
                        return (<a href='https://sites.google.com/view/privacy-policy-chathelpie/home'>{aboutUs}</a>)
                    })
                }
            </div>
        </div>
    )

}

export default Leftbar1a5p18;